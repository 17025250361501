
import { defineComponent, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import useScreening from '@/hooks/useScreening'
import useLoadSlides from '@/hooks/useLoadSlides'

import ThumbnailSlide from '@/views/components/ThumbnailSlide/index.vue'

export default defineComponent({
  name: 'thumbnails',
  components: {
    ThumbnailSlide,
  },
  setup() {
    const { slides } = storeToRefs(useSlidesStore())

    const { slidesLoadLimit } = useLoadSlides()
    const { enterScreening } = useScreening()

    const mobileRef = ref<HTMLElement>()
    const slideWidth = ref(0)

    onMounted(() => {
      if (!mobileRef.value) return
      slideWidth.value = mobileRef.value.clientWidth - 10
    })

    return {
      slides,
      slidesLoadLimit,
      mobileRef,
      slideWidth,
      enterScreening,
    }
  },
})
