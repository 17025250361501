
import { defineComponent, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { PPTTextElement } from '@/types/slides'
import emitter, { EmitterEvents, RichTextCommand } from '@/utils/emitter'
import { WEB_FONTS } from '@/configs/font'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'
import { message } from 'ant-design-vue'

import ElementOpacity from '../common/ElementOpacity.vue'
import ElementOutline from '../common/ElementOutline.vue'
import ElementShadow from '../common/ElementShadow.vue'

const presetStyles = [
  {
    label: '大标题',
    style: {
      fontSize: '26px',
      fontWeight: 700,
    },
    cmd: [
      { command: 'clear' },
      { command: 'fontsize', value: '48px' },
      { command: 'align', value: 'center' },
      { command: 'bold' },
    ],
  },
  {
    label: '小标题',
    style: {
      fontSize: '22px',
      fontWeight: 700,
    },
    cmd: [
      { command: 'clear' },
      { command: 'fontsize', value: '36px' },
      { command: 'align', value: 'center' },
      { command: 'bold' },
    ],
  },
  {
    label: '正文',
    style: {
      fontSize: '20px',
    },
    cmd: [
      { command: 'clear' },
      { command: 'fontsize', value: '20px' },
    ],
  },
  {
    label: '正文[小]',
    style: {
      fontSize: '18px',
    },
    cmd: [
      { command: 'clear' },
      { command: 'fontsize', value: '18px' },
    ],
  },
  {
    label: '注释 1',
    style: {
      fontSize: '16px',
      fontStyle: 'italic',
    },
    cmd: [
      { command: 'clear' },
      { command: 'fontsize', value: '16px' },
      { command: 'em' },
    ],
  },
  {
    label: '注释 2',
    style: {
      fontSize: '16px',
      textDecoration: 'underline',
    },
    cmd: [
      { command: 'clear' },
      { command: 'fontsize', value: '16px' },
      { command: 'underline' },
    ],
  },
]

const webFonts = WEB_FONTS

export default defineComponent({
  name: 'text-style-panel',
  components: {
    ElementOpacity,
    ElementOutline,
    ElementShadow,
  },
  setup() {
    const slidesStore = useSlidesStore()
    const { handleElement, handleElementId, richTextAttrs, availableFonts } = storeToRefs(useMainStore())

    const { addHistorySnapshot } = useHistorySnapshot()

    const updateElement = (props: Partial<PPTTextElement>) => {
      slidesStore.updateElement({ id: handleElementId.value, props })
      addHistorySnapshot()
    }

    const fill = ref<string>()
    const lineHeight = ref<number>()
    const wordSpace = ref<number>()

    watch(handleElement, () => {
      if (!handleElement.value || handleElement.value.type !== 'text') return

      fill.value = handleElement.value.fill || '#000'
      lineHeight.value = handleElement.value.lineHeight || 1.5
      wordSpace.value = handleElement.value.wordSpace || 0
    }, { deep: true, immediate: true })

    const fontSizeOptions = [
      '12px', '14px', '16px', '18px', '20px', '22px', '24px', '28px', '32px',
      '36px', '40px', '44px', '48px', '54px', '60px', '66px', '72px', '76px',
      '80px', '88px', '96px', '104px', '112px', '120px',
    ]
    const lineHeightOptions = [0.9, 1.0, 1.15, 1.2, 1.4, 1.5, 1.8, 2.0, 2.5, 3.0]
    const wordSpaceOptions = [0, 1, 2, 3, 4, 5, 6, 8, 10]

    // 设置行高
    const updateLineHeight = (value: number) => {
      updateElement({ lineHeight: value })
    }

    // 设置字间距
    const updateWordSpace = (value: number) => {
      updateElement({ wordSpace: value })
    }

    // 设置文本框填充
    const updateFill = (value: string) => {
      updateElement({ fill: value })
    }

    // 发射富文本设置命令
    const emitRichTextCommand = (command: string, value?: string) => {
      emitter.emit(EmitterEvents.RICH_TEXT_COMMAND, { command, value })
    }

    // 发射富文本设置命令（批量）
    const emitBatchRichTextCommand = (payload: RichTextCommand[]) => {
      emitter.emit(EmitterEvents.RICH_TEXT_COMMAND, payload)
    }

    // 设置富文本超链接
    const link = ref('')
    const linkPopoverVisible = ref(false)

    watch(richTextAttrs, () => linkPopoverVisible.value = false)

    const openLinkPopover = () => {
      link.value = richTextAttrs.value.link
      linkPopoverVisible.value = true
    }
    const updateLink = (link: string) => {
      if (link) {
        const linkRegExp = /^(https?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-.,@?^=%&:\/~+#]*[\w\-@?^=%&\/~+#])?$/
        if (!linkRegExp.test(link)) return message.error('不是正确的网页链接地址')
      }
      emitRichTextCommand('link', link)
      linkPopoverVisible.value = false
    }

    return {
      fill,
      lineHeight,
      wordSpace,
      richTextAttrs,
      availableFonts,
      webFonts,
      fontSizeOptions,
      lineHeightOptions,
      wordSpaceOptions,
      updateLineHeight,
      updateWordSpace,
      updateFill,
      emitRichTextCommand,
      emitBatchRichTextCommand,
      presetStyles,
      link,
      linkPopoverVisible,
      openLinkPopover,
      updateLink,
    }
  },
})
