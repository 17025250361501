import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-element", `base-element-${_ctx.elementInfo.id}`]),
    style: _normalizeStyle({
      zIndex: _ctx.elementIndex,
    })
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentElementComponent), {
      elementInfo: _ctx.elementInfo,
      target: "thumbnail"
    }, null, 8, ["elementInfo"]))
  ], 6))
}