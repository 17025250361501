import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuContent = _resolveComponent("MenuContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "mask",
      onContextmenu: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.removeContextmenu()), ["prevent"])),
      onMousedown: _cache[1] || (_cache[1] = ($event: any) => (_ctx.removeContextmenu()))
    }, null, 32),
    _createElementVNode("div", {
      class: "contextmenu",
      style: _normalizeStyle({
      left: _ctx.style.left + 'px',
      top: _ctx.style.top + 'px',
    }),
      onContextmenu: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
    }, [
      _createVNode(_component_MenuContent, {
        menus: _ctx.menus,
        handleClickMenuItem: _ctx.handleClickMenuItem
      }, null, 8, ["menus", "handleClickMenuItem"])
    ], 36)
  ], 64))
}