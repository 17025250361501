import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b1f9460"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageOutline = _resolveComponent("ImageOutline")!

  return (_openBlock(), _createElementBlock("div", {
    class: "base-element-image",
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
      width: _ctx.elementInfo.width + 'px',
      height: _ctx.elementInfo.height + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "rotate-wrapper",
      style: _normalizeStyle({ transform: `rotate(${_ctx.elementInfo.rotate}deg)` })
    }, [
      _createElementVNode("div", {
        class: "element-content",
        style: _normalizeStyle({
          filter: _ctx.shadowStyle ? `drop-shadow(${_ctx.shadowStyle})` : '',
          transform: _ctx.flipStyle,
        })
      }, [
        _createVNode(_component_ImageOutline, { elementInfo: _ctx.elementInfo }, null, 8, ["elementInfo"]),
        _createElementVNode("div", {
          class: "image-content",
          style: _normalizeStyle({ clipPath: _ctx.clipShape.style })
        }, [
          _createElementVNode("img", {
            src: _ctx.elementInfo.src,
            draggable: false,
            style: _normalizeStyle({
              top: _ctx.imgPosition.top,
              left: _ctx.imgPosition.left,
              width: _ctx.imgPosition.width,
              height: _ctx.imgPosition.height,
              filter: _ctx.filter,
            }),
            alt: ""
          }, null, 12, _hoisted_1)
        ], 4)
      ], 4)
    ], 4)
  ], 4))
}