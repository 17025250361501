
import { computed, defineComponent, PropType } from 'vue'
import tinycolor, { ColorFormats } from 'tinycolor2'

export default defineComponent({
  name: 'editable-input',
  emits: ['colorChange'],
  props: {
    value: {
      type: Object as PropType<ColorFormats.RGBA>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const val = computed(() => {
      let _hex = ''
      if (props.value.a < 1) _hex = tinycolor(props.value).toHex8String().toUpperCase()
      else _hex = tinycolor(props.value).toHexString().toUpperCase()
      return _hex.replace('#', '')
    })

    const handleInput = (e: Event) => {
      const value = (e.target as HTMLInputElement).value
      if (value.length >= 6) emit('colorChange', tinycolor(value).toRgb())
    }

    return {
      val,
      handleInput,
    }
  },
})
