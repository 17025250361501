import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05b64c90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "color-picker" }
const _hoisted_2 = { class: "picker-saturation-wrap" }
const _hoisted_3 = { class: "picker-controls" }
const _hoisted_4 = { class: "picker-color-wrap" }
const _hoisted_5 = { class: "picker-sliders" }
const _hoisted_6 = { class: "picker-hue-wrap" }
const _hoisted_7 = { class: "picker-alpha-wrap" }
const _hoisted_8 = { class: "picker-field" }
const _hoisted_9 = { class: "picker-presets" }
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "picker-gradient-presets" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "picker-presets" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = {
  key: 0,
  class: "recent-colors-title"
}
const _hoisted_16 = { class: "recent-colors" }
const _hoisted_17 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Saturation = _resolveComponent("Saturation")!
  const _component_Checkboard = _resolveComponent("Checkboard")!
  const _component_Hue = _resolveComponent("Hue")!
  const _component_Alpha = _resolveComponent("Alpha")!
  const _component_EditableInput = _resolveComponent("EditableInput")!
  const _component_IconPlatte = _resolveComponent("IconPlatte")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Saturation, {
        value: _ctx.color,
        hue: _ctx.hue,
        onColorChange: _cache[0] || (_cache[0] = value => _ctx.changeColor(value))
      }, null, 8, ["value", "hue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", {
          class: "picker-current-color",
          style: _normalizeStyle({ background: _ctx.currentColor })
        }, null, 4),
        _createVNode(_component_Checkboard)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_Hue, {
            value: _ctx.color,
            hue: _ctx.hue,
            onColorChange: _cache[1] || (_cache[1] = value => _ctx.changeColor(value))
          }, null, 8, ["value", "hue"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_Alpha, {
            value: _ctx.color,
            onColorChange: _cache[2] || (_cache[2] = value => _ctx.changeColor(value))
          }, null, 8, ["value"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_EditableInput, {
        class: "input",
        value: _ctx.color,
        onColorChange: _cache[3] || (_cache[3] = value => _ctx.changeColor(value))
      }, null, 8, ["value"]),
      _createElementVNode("div", {
        class: "straw",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.pickColor()))
      }, [
        _createVNode(_component_IconPlatte)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.themeColors, (c) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "picker-presets-color",
          key: c,
          style: _normalizeStyle({ background: c }),
          onClick: ($event: any) => (_ctx.selectPresetColor(c))
        }, null, 12, _hoisted_10))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_11, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.presetColors, (col, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "picker-gradient-col",
          key: index
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(col, (c) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "picker-gradient-color",
              key: c,
              style: _normalizeStyle({ background: c }),
              onClick: ($event: any) => (_ctx.selectPresetColor(c))
            }, null, 12, _hoisted_12))
          }), 128))
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_13, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.standardColors, (c) => {
        return (_openBlock(), _createElementBlock("div", {
          key: c,
          class: "picker-presets-color",
          style: _normalizeStyle({ background: c }),
          onClick: ($event: any) => (_ctx.selectPresetColor(c))
        }, null, 12, _hoisted_14))
      }), 128))
    ]),
    (_ctx.recentColors.length)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, "最近使用："))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_16, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recentColors, (c) => {
        return (_openBlock(), _createElementBlock("div", {
          key: c,
          class: "picker-presets-color",
          style: _normalizeStyle({ background: c }),
          onClick: ($event: any) => (_ctx.selectPresetColor(c))
        }, null, 12, _hoisted_17))
      }), 128))
    ])
  ]))
}