
import { computed, defineComponent, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { PPTAnimation } from '@/types/slides'
import { ANIMATIONS } from '@/configs/animation'
import { ELEMENT_TYPE_ZH } from '@/configs/element'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import Draggable from 'vuedraggable'

const defaultDuration = 1000

const animationTypes: { [key: string]: string } = {}
for (const type of ANIMATIONS) {
  for (const animation of type.children) {
    animationTypes[animation.value] = animation.name
  }
}

export default defineComponent({
  name: 'element-animation-panel',
  components: {
    Draggable,
  },
  setup() {
    const slidesStore = useSlidesStore()
    const { handleElement, handleElementId } = storeToRefs(useMainStore())
    const { currentSlide, currentSlideAnimations } = storeToRefs(slidesStore)

    const hoverPreviewAnimation = ref('')
    const animationPoolVisible = ref(false)

    const { addHistorySnapshot } = useHistorySnapshot()

    const animations = ANIMATIONS

    // 当前页面的动画列表
    const animationSequence = computed(() => {
      if (!currentSlideAnimations.value) return []
      const animationSequence = []
      for (const animation of currentSlideAnimations.value) {
        const el = currentSlide.value.elements.find(el => el.id === animation.elId)
        if (!el) continue
        const elType = ELEMENT_TYPE_ZH[el.type]
        const animationType = animationTypes[animation.type]

        animationSequence.push({
          ...animation,
          elType,
          animationType,
        })
      }
      return animationSequence
    })

    // 当前选中元素的入场动画信息
    const handleElementAnimation = computed(() => {
      const animations = currentSlideAnimations.value || []
      const animation = animations.find(item => item.elId === handleElementId.value)
      return animation || null
    })

    // 当前选中元素的入场动画名称
    const handleElementAnimationName = computed(() => {
      if (!handleElementAnimation.value) return null
      return animationTypes[handleElementAnimation.value.type]
    })

    // 删除元素入场动画
    const deleteAnimation = (elId: string) => {
      const animations = (currentSlideAnimations.value as PPTAnimation[]).filter(item => item.elId !== elId)
      slidesStore.updateSlide({ animations })
      addHistorySnapshot()
    }

    // 拖拽修改入场动画顺序后同步数据
    const handleDragEnd = (eventData: { newIndex: number; oldIndex: number }) => {
      const { newIndex, oldIndex } = eventData
      if (oldIndex === newIndex) return

      const animations: PPTAnimation[] = JSON.parse(JSON.stringify(currentSlideAnimations.value))
      const animation = animations[oldIndex]
      animations.splice(oldIndex, 1)
      animations.splice(newIndex, 0, animation)
      
      slidesStore.updateSlide({ animations })
      addHistorySnapshot()
    }

    // 执行入场动画预览
    const runAnimation = (elId: string, animationType: string, duration: number) => {
      const prefix = 'animate__'
      const elRef = document.querySelector(`#editable-element-${elId} [class^=editable-element-]`)
      if (elRef) {
        const animationName = `${prefix}${animationType}`
        document.documentElement.style.setProperty('--animate-duration', `${duration}ms`)
        elRef.classList.add(`${prefix}animated`, animationName)

        const handleAnimationEnd = () => {
          document.documentElement.style.removeProperty('--animate-duration')
          elRef.classList.remove(`${prefix}animated`, animationName)
        }
        elRef.addEventListener('animationend', handleAnimationEnd, { once: true })
      }
    }

    // 修改元素入场动画，并执行一次预览
    const updateElementAnimation = (type: string) => {
      if (!currentSlideAnimations.value) return

      const animations = currentSlideAnimations.value.map(item => {
        if (item.elId === handleElementId.value) return { ...item, type }
        return item
      })
      slidesStore.updateSlide({ animations })
      animationPoolVisible.value = false
      addHistorySnapshot()

      const animationItem = currentSlideAnimations.value.find(item => item.elId === handleElementId.value)
      const duration = animationItem?.duration || defaultDuration

      runAnimation(handleElementId.value, type, duration)
    }

    // 修改元素入场动画持续时间
    const updateElementAnimationDuration = (duration: number) => {
      if (!currentSlideAnimations.value) return
      if (duration < 100 || duration > 5000) return

      const animations = currentSlideAnimations.value.map(item => {
        if (item.elId === handleElementId.value) return { ...item, duration }
        return item
      })
      slidesStore.updateSlide({ animations })
      addHistorySnapshot()
    }

    // 添加元素入场动画，并执行一次预览
    const addAnimation = (type: string) => {
      if (handleElementAnimationName.value) {
        updateElementAnimation(type)
        return
      }
      const animations: PPTAnimation[] = currentSlideAnimations.value ? JSON.parse(JSON.stringify(currentSlideAnimations.value)) : []
      animations.push({
        elId: handleElementId.value,
        type,
        duration: defaultDuration,
      })
      slidesStore.updateSlide({ animations })
      animationPoolVisible.value = false
      addHistorySnapshot()

      runAnimation(handleElementId.value, type, defaultDuration)
    }

    // 动画选择面板打开500ms后再移除遮罩层，否则打开面板后迅速滑入鼠标预览会导致抖动
    const popoverMaskHide = ref(false)
    const handlePopoverVisibleChange = (visible: boolean) => {
      if (visible) {
        setTimeout(() => popoverMaskHide.value = true, 500)
      }
      else popoverMaskHide.value = false
    }

    return {
      handleElement,
      animationPoolVisible,
      animations,
      animationSequence,
      hoverPreviewAnimation,
      handleElementAnimation,
      handleElementAnimationName,
      popoverMaskHide,
      addAnimation,
      deleteAnimation,
      handleDragEnd,
      runAnimation,
      updateElementAnimationDuration,
      handlePopoverVisibleChange,
    }
  },
})
