
import { defineComponent, PropType, ref } from 'vue'
import WritingBoard from '@/components/WritingBoard.vue'

const writingBoardColors = ['#000000', '#ffffff', '#1e497b', '#4e81bb', '#e2534d', '#9aba60', '#8165a0', '#47acc5', '#f9974c']

interface Position {
  left?: number | string;
  right?: number | string;
  top?: number | string;
  bottom?: number | string;
}

export default defineComponent({
  name: 'writing-board-tool',
  emits: ['close'],
  components: {
    WritingBoard,
  },
  props: {
    slideWidth: {
      type: Number,
      required: true,
    },
    slideHeight: {
      type: Number,
      required: true,
    },
    position: {
      type: Object as PropType<Position>,
      default: () => ({
        right: '5px',
        bottom: '5px',
      })
    },
  },
  setup(props, { emit }) {
    const writingBoardRef = ref()
    const writingBoardColor = ref('#e2534d')
    const writingBoardModel = ref<'pen' | 'eraser'>('pen')
    const blackboard = ref(false)

    // 切换到画笔状态
    const changePen = () => {
      writingBoardModel.value = 'pen'
    }

    // 切换到橡皮状态
    const changeEraser = () => {
      writingBoardModel.value = 'eraser'
    }

    // 清除画布上的墨迹
    const clearCanvas = () => {
      writingBoardRef.value.clearCanvas()
    }

    // 修改画笔颜色，如果当前不处于画笔状态则先切换到画笔状态
    const changeColor = (color: string) => {
      if (writingBoardModel.value !== 'pen') writingBoardModel.value = 'pen'
      writingBoardColor.value = color
    }
    
    // 关闭写字板
    const closeWritingBoard = () => {
      emit('close')
    }

    return {
      writingBoardRef,
      writingBoardColors,
      writingBoardColor,
      writingBoardModel,
      blackboard,
      changePen,
      changeEraser,
      clearCanvas,
      changeColor,
      closeWritingBoard,
    }
  },
})
