import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-72b587f9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "mobile",
  ref: "mobileRef"
}
const _hoisted_2 = { class: "thumbnail-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alert = _resolveComponent("Alert")!
  const _component_ThumbnailSlide = _resolveComponent("ThumbnailSlide")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Alert, {
      class: "tip",
      message: "注意",
      description: "移动设备下仅支持预览，请在PC上进行编辑",
      type: "warning",
      closable: "",
      "show-icon": ""
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slides, (slide, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "thumbnail-item",
          key: slide.id
        }, [
          _createVNode(_component_ThumbnailSlide, {
            slide: slide,
            size: _ctx.slideWidth,
            visible: index < _ctx.slidesLoadLimit
          }, null, 8, ["slide", "size", "visible"])
        ]))
      }), 128))
    ])
  ], 512))
}