import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseChartElement = _resolveComponent("BaseChartElement")!

  return (_openBlock(), _createBlock(_component_BaseChartElement, {
    class: "screen-element-chart",
    elementInfo: _ctx.elementInfo,
    needScaleSize: false
  }, null, 8, ["elementInfo"]))
}