import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-288ee59d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ImageClipHandler = _resolveComponent("ImageClipHandler")!
  const _component_ImageOutline = _resolveComponent("ImageOutline")!
  const _directive_contextmenu = _resolveDirective("contextmenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editable-element-image", { 'lock': _ctx.elementInfo.lock }]),
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
      width: _ctx.elementInfo.width + 'px',
      height: _ctx.elementInfo.height + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "rotate-wrapper",
      style: _normalizeStyle({ transform: `rotate(${_ctx.elementInfo.rotate}deg)` })
    }, [
      (_ctx.isCliping)
        ? (_openBlock(), _createBlock(_component_ImageClipHandler, {
            key: 0,
            src: _ctx.elementInfo.src,
            clipData: _ctx.elementInfo.clip,
            width: _ctx.elementInfo.width,
            height: _ctx.elementInfo.height,
            top: _ctx.elementInfo.top,
            left: _ctx.elementInfo.left,
            rotate: _ctx.elementInfo.rotate,
            clipPath: _ctx.clipShape.style,
            onClip: _cache[0] || (_cache[0] = range => _ctx.handleClip(range))
          }, null, 8, ["src", "clipData", "width", "height", "top", "left", "rotate", "clipPath"]))
        : _withDirectives((_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "element-content",
            style: _normalizeStyle({
          filter: _ctx.shadowStyle ? `drop-shadow(${_ctx.shadowStyle})` : '',
          transform: _ctx.flipStyle,
        }),
            onMousedown: _cache[2] || (_cache[2] = $event => _ctx.handleSelectElement($event))
          }, [
            _createVNode(_component_ImageOutline, { elementInfo: _ctx.elementInfo }, null, 8, ["elementInfo"]),
            _createElementVNode("div", {
              class: "image-content",
              style: _normalizeStyle({ clipPath: _ctx.clipShape.style })
            }, [
              _createElementVNode("img", {
                src: _ctx.elementInfo.src,
                draggable: false,
                style: _normalizeStyle({
              top: _ctx.imgPosition.top,
              left: _ctx.imgPosition.left,
              width: _ctx.imgPosition.width,
              height: _ctx.imgPosition.height,
              filter: _ctx.filter,
            }),
                onDragstart: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
                alt: ""
              }, null, 44, _hoisted_1)
            ], 4)
          ], 36)), [
            [_directive_contextmenu, _ctx.contextmenus]
          ])
    ], 4)
  ], 6))
}