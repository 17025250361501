
import { defineComponent, ref } from 'vue'
import { message } from 'ant-design-vue'

type TypeKey = 'video' | 'audio'
interface TabItem {
  key: TypeKey;
  label: string;
}

export default defineComponent({
  name: 'media-input',
  emits: ['insertVideo', 'insertAudio', 'close'],
  setup(props, { emit }) {
    const type = ref<TypeKey>('video')

    const videoSrc = ref('https://mazwai.com/videvo_files/video/free/2019-01/small_watermarked/181004_04_Dolphins-Whale_06_preview.webm')
    const audioSrc = ref('https://freesound.org/data/previews/614/614107_11861866-lq.mp3')

    const tabs: TabItem[] = [
      { key: 'video', label: '视频' },
      { key: 'audio', label: '音频' },
    ]

    const insertVideo = () => {
      if (!videoSrc.value) return message.error('请先输入正确的视频地址')
      emit('insertVideo', videoSrc.value)
    }

    const insertAudio = () => {
      if (!audioSrc.value) return message.error('请先输入正确的音频地址')
      emit('insertAudio', audioSrc.value)
    }

    const close = () => emit('close')

    return {
      type,
      videoSrc,
      audioSrc,
      tabs,
      insertVideo,
      insertAudio,
      close,
    }
  },
})
