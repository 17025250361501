
import { defineComponent } from 'vue'
import { SHAPE_LIST, ShapePoolItem } from '@/configs/shapes'

export default defineComponent({
  name: 'shape-pool',
  emits: ['select'],
  setup(props, { emit }) {
    const shapeList = SHAPE_LIST

    const selectShape = (shape: ShapePoolItem) => {
      emit('select', shape)
    }

    return {
      shapeList,
      selectShape,
    }
  },
})
