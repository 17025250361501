import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2b77dbb0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = {
  key: 1,
  class: "link"
}
const _hoisted_3 = { class: "btns" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!

  return (_openBlock(), _createElementBlock("div", {
    class: "link-handler",
    style: _normalizeStyle({ top: _ctx.height * _ctx.canvasScale + 10 + 'px' })
  }, [
    (_ctx.link.type === 'web')
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "link",
          href: _ctx.link.target,
          target: "_blank"
        }, _toDisplayString(_ctx.link.target), 9, _hoisted_1))
      : (_openBlock(), _createElementBlock("a", _hoisted_2, "幻灯片页面 " + _toDisplayString(_ctx.link.target), 1)),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "btn",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openLinkDialog()))
      }, "更换"),
      _createVNode(_component_Divider, { type: "vertical" }),
      _createElementVNode("div", {
        class: "btn",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.removeLink(_ctx.elementInfo)))
      }, "移除")
    ])
  ], 4))
}