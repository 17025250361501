
import { computed, PropType, defineComponent, provide } from 'vue'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import { Slide } from '@/types/slides'
import { injectKeySlideId } from '@/types/injectKey'
import { VIEWPORT_SIZE } from '@/configs/canvas'
import useSlideBackgroundStyle from '@/hooks/useSlideBackgroundStyle'

import ScreenElement from './ScreenElement.vue'

export default defineComponent({
  name: 'screen-slide',
  components: {
    ScreenElement,
  },
  props: {
    slide: {
      type: Object as PropType<Slide>,
      required: true,
    },
    scale: {
      type: Number,
      required: true,
    },
    animationIndex: {
      type: Number,
      default: -1,
    },
    turnSlideToId: {
      type: Function as PropType<(id: string) => void>,
      required: true,
    },
    manualExitFullscreen: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const { viewportRatio } = storeToRefs(useSlidesStore())

    const background = computed(() => props.slide.background)
    const { backgroundStyle } = useSlideBackgroundStyle(background)

    const slideId = computed(() => props.slide.id)
    provide(injectKeySlideId, slideId)

    return {
      backgroundStyle,
      VIEWPORT_SIZE,
      viewportRatio,
    }
  },
})
