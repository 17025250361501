
import { defineComponent, PropType } from 'vue'
import { PPTTableElement } from '@/types/slides'

import StaticTable from './StaticTable.vue'

export default defineComponent({
  name: 'base-element-table',
  components: {
    StaticTable,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTTableElement>,
      required: true,
    },
  },
})
