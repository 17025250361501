import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-603a705d"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["d", "stroke", "stroke-width", "stroke-dasharray", "marker-start", "marker-end"]
const _hoisted_3 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinePointMarker = _resolveComponent("LinePointMarker")!
  const _directive_contextmenu = _resolveDirective("contextmenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editable-element-shape", { 'lock': _ctx.elementInfo.lock }]),
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "element-content",
      style: _normalizeStyle({ filter: _ctx.shadowStyle ? `drop-shadow(${_ctx.shadowStyle})` : '' }),
      onMousedown: _cache[0] || (_cache[0] = $event => _ctx.handleSelectElement($event))
    }, [
      (_openBlock(), _createElementBlock("svg", {
        overflow: "visible",
        width: _ctx.svgWidth,
        height: _ctx.svgHeight
      }, [
        _createElementVNode("defs", null, [
          (_ctx.elementInfo.points[0])
            ? (_openBlock(), _createBlock(_component_LinePointMarker, {
                key: 0,
                id: _ctx.elementInfo.id,
                position: "start",
                type: _ctx.elementInfo.points[0],
                color: _ctx.elementInfo.color,
                baseSize: _ctx.elementInfo.width
              }, null, 8, ["id", "type", "color", "baseSize"]))
            : _createCommentVNode("", true),
          (_ctx.elementInfo.points[1])
            ? (_openBlock(), _createBlock(_component_LinePointMarker, {
                key: 1,
                id: _ctx.elementInfo.id,
                position: "end",
                type: _ctx.elementInfo.points[1],
                color: _ctx.elementInfo.color,
                baseSize: _ctx.elementInfo.width
              }, null, 8, ["id", "type", "color", "baseSize"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("path", {
          class: "line-point",
          d: _ctx.path,
          stroke: _ctx.elementInfo.color,
          "stroke-width": _ctx.elementInfo.width,
          "stroke-dasharray": _ctx.lineDashArray,
          fill: "none",
          "stroke-linecap": "",
          "stroke-linejoin": "",
          "stroke-miterlimit": "",
          "marker-start": _ctx.elementInfo.points[0] ? `url(#${_ctx.elementInfo.id}-${_ctx.elementInfo.points[0]}-start)` : '',
          "marker-end": _ctx.elementInfo.points[1] ? `url(#${_ctx.elementInfo.id}-${_ctx.elementInfo.points[1]}-end)` : ''
        }, null, 8, _hoisted_2),
        _withDirectives(_createElementVNode("path", {
          class: "line-path",
          d: _ctx.path,
          stroke: "transparent",
          "stroke-width": "20",
          fill: "none"
        }, null, 8, _hoisted_3), [
          [_directive_contextmenu, _ctx.contextmenus]
        ])
      ], 8, _hoisted_1))
    ], 36)
  ], 6))
}