
import { computed, defineComponent, PropType, provide } from 'vue'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import { injectKeySlideScale } from '@/types/injectKey'
import { VIEWPORT_SIZE } from '@/configs/canvas'

import ScreenSlide from './ScreenSlide.vue'

export default defineComponent({
  name: 'screen-slide-list',
  components: {
    ScreenSlide,
  },
  props: {
    slideWidth: {
      type: Number,
      required: true,
    },
    slideHeight: {
      type: Number,
      required: true,
    },
    animationIndex: {
      type: Number,
      default: -1,
    },
    turnSlideToId: {
      type: Function as PropType<(id: string) => void>,
      required: true,
    },
    manualExitFullscreen: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const { slides, slideIndex, currentSlide } = storeToRefs(useSlidesStore())

    const scale = computed(() => props.slideWidth / VIEWPORT_SIZE)
    provide(injectKeySlideScale, scale)

    return {
      slides,
      slideIndex,
      currentSlide,
      scale,
    }
  },
})
