import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ae7c9aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "canvas-tool" }
const _hoisted_2 = { class: "left-handler" }
const _hoisted_3 = { class: "add-element-handler" }
const _hoisted_4 = { class: "right-handler" }
const _hoisted_5 = { class: "text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconBack = _resolveComponent("IconBack")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_IconNext = _resolveComponent("IconNext")!
  const _component_IconFontSize = _resolveComponent("IconFontSize")!
  const _component_IconPicture = _resolveComponent("IconPicture")!
  const _component_FileInput = _resolveComponent("FileInput")!
  const _component_ShapePool = _resolveComponent("ShapePool")!
  const _component_IconGraphicDesign = _resolveComponent("IconGraphicDesign")!
  const _component_Popover = _resolveComponent("Popover")!
  const _component_LinePool = _resolveComponent("LinePool")!
  const _component_IconConnection = _resolveComponent("IconConnection")!
  const _component_ChartPool = _resolveComponent("ChartPool")!
  const _component_IconChartProportion = _resolveComponent("IconChartProportion")!
  const _component_TableGenerator = _resolveComponent("TableGenerator")!
  const _component_IconInsertTable = _resolveComponent("IconInsertTable")!
  const _component_IconFormula = _resolveComponent("IconFormula")!
  const _component_MediaInput = _resolveComponent("MediaInput")!
  const _component_IconVideoTwo = _resolveComponent("IconVideoTwo")!
  const _component_IconMinus = _resolveComponent("IconMinus")!
  const _component_IconPlus = _resolveComponent("IconPlus")!
  const _component_IconFullScreen = _resolveComponent("IconFullScreen")!
  const _component_LaTeXEditor = _resolveComponent("LaTeXEditor")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Tooltip, {
        mouseLeaveDelay: 0,
        mouseEnterDelay: 0.5,
        title: "撤销"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconBack, {
            class: _normalizeClass(["handler-item", { 'disable': !_ctx.canUndo }]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.undo()))
          }, null, 8, ["class"])
        ]),
        _: 1
      }, 8, ["mouseEnterDelay"]),
      _createVNode(_component_Tooltip, {
        mouseLeaveDelay: 0,
        mouseEnterDelay: 0.5,
        title: "重做"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconNext, {
            class: _normalizeClass(["handler-item", { 'disable': !_ctx.canRedo }]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.redo()))
          }, null, 8, ["class"])
        ]),
        _: 1
      }, 8, ["mouseEnterDelay"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Tooltip, {
        mouseLeaveDelay: 0,
        mouseEnterDelay: 0.5,
        title: "插入文字"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconFontSize, {
            class: "handler-item",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.drawText()))
          })
        ]),
        _: 1
      }, 8, ["mouseEnterDelay"]),
      _createVNode(_component_FileInput, {
        onChange: _cache[3] || (_cache[3] = files => _ctx.insertImageElement(files))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Tooltip, {
            mouseLeaveDelay: 0,
            mouseEnterDelay: 0.5,
            title: "插入图片"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconPicture, { class: "handler-item" })
            ]),
            _: 1
          }, 8, ["mouseEnterDelay"])
        ]),
        _: 1
      }),
      _createVNode(_component_Popover, {
        trigger: "click",
        visible: _ctx.shapePoolVisible,
        "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.shapePoolVisible) = $event))
      }, {
        content: _withCtx(() => [
          _createVNode(_component_ShapePool, {
            onSelect: _cache[4] || (_cache[4] = shape => _ctx.drawShape(shape))
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Tooltip, {
            mouseLeaveDelay: 0,
            mouseEnterDelay: 0.5,
            title: "插入形状"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconGraphicDesign, { class: "handler-item" })
            ]),
            _: 1
          }, 8, ["mouseEnterDelay"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Popover, {
        trigger: "click",
        visible: _ctx.linePoolVisible,
        "onUpdate:visible": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.linePoolVisible) = $event))
      }, {
        content: _withCtx(() => [
          _createVNode(_component_LinePool, {
            onSelect: _cache[6] || (_cache[6] = line => _ctx.drawLine(line))
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Tooltip, {
            mouseLeaveDelay: 0,
            mouseEnterDelay: 0.5,
            title: "插入线条"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconConnection, { class: "handler-item" })
            ]),
            _: 1
          }, 8, ["mouseEnterDelay"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Popover, {
        trigger: "click",
        visible: _ctx.chartPoolVisible,
        "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.chartPoolVisible) = $event))
      }, {
        content: _withCtx(() => [
          _createVNode(_component_ChartPool, {
            onSelect: _cache[8] || (_cache[8] = chart => { _ctx.createChartElement(chart); _ctx.chartPoolVisible = false })
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Tooltip, {
            mouseLeaveDelay: 0,
            mouseEnterDelay: 0.5,
            title: "插入图表"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconChartProportion, { class: "handler-item" })
            ]),
            _: 1
          }, 8, ["mouseEnterDelay"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Popover, {
        trigger: "click",
        visible: _ctx.tableGeneratorVisible,
        "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.tableGeneratorVisible) = $event))
      }, {
        content: _withCtx(() => [
          _createVNode(_component_TableGenerator, {
            onClose: _cache[10] || (_cache[10] = ($event: any) => (_ctx.tableGeneratorVisible = false)),
            onInsert: _cache[11] || (_cache[11] = ({ row, col }) => { _ctx.createTableElement(row, col); _ctx.tableGeneratorVisible = false })
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Tooltip, {
            mouseLeaveDelay: 0,
            mouseEnterDelay: 0.5,
            title: "插入表格"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconInsertTable, { class: "handler-item" })
            ]),
            _: 1
          }, 8, ["mouseEnterDelay"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Tooltip, {
        mouseLeaveDelay: 0,
        mouseEnterDelay: 0.5,
        title: "插入公式"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconFormula, {
            class: "handler-item",
            onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.latexEditorVisible = true))
          })
        ]),
        _: 1
      }, 8, ["mouseEnterDelay"]),
      _createVNode(_component_Popover, {
        trigger: "click",
        visible: _ctx.mediaInputVisible,
        "onUpdate:visible": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.mediaInputVisible) = $event))
      }, {
        content: _withCtx(() => [
          _createVNode(_component_MediaInput, {
            onClose: _cache[14] || (_cache[14] = ($event: any) => (_ctx.mediaInputVisible = false)),
            onInsertVideo: _cache[15] || (_cache[15] = src => { _ctx.createVideoElement(src); _ctx.mediaInputVisible = false }),
            onInsertAudio: _cache[16] || (_cache[16] = src => { _ctx.createAudioElement(src); _ctx.mediaInputVisible = false })
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_Tooltip, {
            mouseLeaveDelay: 0,
            mouseEnterDelay: 0.5,
            title: "插入音视频"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_IconVideoTwo, { class: "handler-item" })
            ]),
            _: 1
          }, 8, ["mouseEnterDelay"])
        ]),
        _: 1
      }, 8, ["visible"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_IconMinus, {
        class: "handler-item viewport-size",
        onClick: _cache[18] || (_cache[18] = ($event: any) => (_ctx.scaleCanvas('-')))
      }),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.canvasScalePercentage), 1),
      _createVNode(_component_IconPlus, {
        class: "handler-item viewport-size",
        onClick: _cache[19] || (_cache[19] = ($event: any) => (_ctx.scaleCanvas('+')))
      }),
      _createVNode(_component_Tooltip, {
        mouseLeaveDelay: 0,
        mouseEnterDelay: 0.5,
        title: "适配屏幕"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_IconFullScreen, {
            class: "handler-item viewport-size-adaptation",
            onClick: _cache[20] || (_cache[20] = ($event: any) => (_ctx.setCanvasPercentage(90)))
          })
        ]),
        _: 1
      }, 8, ["mouseEnterDelay"])
    ]),
    _createVNode(_component_Modal, {
      visible: _ctx.latexEditorVisible,
      "onUpdate:visible": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.latexEditorVisible) = $event)),
      footer: null,
      centered: "",
      width: 880,
      destroyOnClose: ""
    }, {
      default: _withCtx(() => [
        _createVNode(_component_LaTeXEditor, {
          onClose: _cache[21] || (_cache[21] = ($event: any) => (_ctx.latexEditorVisible = false)),
          onUpdate: _cache[22] || (_cache[22] = data => { _ctx.createLatexElement(data); _ctx.latexEditorVisible = false })
        })
      ]),
      _: 1
    }, 8, ["visible"])
  ]))
}