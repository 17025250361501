
import { defineComponent } from 'vue'
import { HOTKEY_DOC } from '@/configs/hotkey'

export default defineComponent({
  name: 'hotkey-doc',
  setup() {
    return {
      hotkeys: HOTKEY_DOC,
    }
  },
})
