
import { defineComponent } from 'vue'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import { Slide } from '@/types/slides'

import ThumbnailSlide from '@/views/components/ThumbnailSlide/index.vue'

export default defineComponent({
  name: 'layout-pool',
  emits: ['select'],
  components: {
    ThumbnailSlide,
  },
  setup(props, { emit }) {
    const { layouts } = storeToRefs(useSlidesStore())

    const selectSlideTemplate = (slide: Slide) => {
      emit('select', slide)
    }

    return {
      layouts,
      selectSlideTemplate,
    }
  },
})
