
import { computed, defineComponent, PropType } from 'vue'
import { PPTAudioElement } from '@/types/slides'

export default defineComponent({
  name: 'base-element-audio',
  props: {
    elementInfo: {
      type: Object as PropType<PPTAudioElement>,
      required: true,
    },
  },
  setup(props) {
    const audioIconSize = computed(() => {
      return Math.min(props.elementInfo.width, props.elementInfo.height) + 'px'
    })

    return {
      audioIconSize,
    }
  },
})
