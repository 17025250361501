import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7ed67bb3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "remark" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "resize-handler",
      onMousedown: _cache[0] || (_cache[0] = $event => _ctx.resize($event))
    }, null, 32),
    _createElementVNode("textarea", {
      value: _ctx.remark,
      placeholder: "点击输入演讲者备注",
      onInput: _cache[1] || (_cache[1] = $event => _ctx.handleInput($event))
    }, null, 40, _hoisted_2)
  ]))
}