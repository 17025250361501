import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!
  const _directive_contextmenu = _resolveDirective("contextmenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editable-element-video", { 'lock': _ctx.elementInfo.lock }]),
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
      width: _ctx.elementInfo.width + 'px',
      height: _ctx.elementInfo.height + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "rotate-wrapper",
      style: _normalizeStyle({ transform: `rotate(${_ctx.elementInfo.rotate}deg)` })
    }, [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "element-content",
        onMousedown: _cache[1] || (_cache[1] = $event => _ctx.handleSelectElement($event, false))
      }, [
        _createVNode(_component_VideoPlayer, {
          width: _ctx.elementInfo.width,
          height: _ctx.elementInfo.height,
          src: _ctx.elementInfo.src,
          poster: _ctx.elementInfo.poster,
          scale: _ctx.canvasScale
        }, null, 8, ["width", "height", "src", "poster", "scale"]),
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(['t', 'b', 'l', 'r'], (item) => {
          return _createElementVNode("div", {
            class: _normalizeClass(['handler-border', item]),
            key: item,
            onMousedown: _cache[0] || (_cache[0] = $event => _ctx.handleSelectElement($event))
          }, null, 34)
        }), 64))
      ], 32)), [
        [_directive_contextmenu, _ctx.contextmenus]
      ])
    ], 4)
  ], 6))
}