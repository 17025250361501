import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9b94cc34"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "grid-lines" }
const _hoisted_2 = ["d", "stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      style: _normalizeStyle({
        transform: `scale(${_ctx.canvasScale})`,
      }),
      d: _ctx.path,
      fill: "none",
      stroke: _ctx.gridColor,
      "stroke-width": "0.3",
      "stroke-dasharray": "5"
    }, null, 12, _hoisted_2)
  ]))
}