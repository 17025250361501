
import { PresetChartType } from '@/types/slides'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'chart-pool',
  emits: ['select'],
  setup(props, { emit }) {
    const chartList: PresetChartType[] = ['bar', 'horizontalBar', 'line', 'area', 'scatter', 'pie', 'ring']

    const selectChart = (chart: PresetChartType) => {
      emit('select', chart)
    }

    return {
      chartList,
      selectChart,
    }
  },
})
