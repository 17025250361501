import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-513e91c4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "element-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconVolumeNotice = _resolveComponent("IconVolumeNotice")!

  return (_openBlock(), _createElementBlock("div", {
    class: "base-element-audio",
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
      width: _ctx.elementInfo.width + 'px',
      height: _ctx.elementInfo.height + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "rotate-wrapper",
      style: _normalizeStyle({ transform: `rotate(${_ctx.elementInfo.rotate}deg)` })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_IconVolumeNotice, {
          class: "audio-icon",
          style: _normalizeStyle({
            fontSize: _ctx.audioIconSize,
            color: _ctx.elementInfo.color,
          })
        }, null, 8, ["style"])
      ])
    ], 4)
  ], 4))
}