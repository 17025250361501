
import { PropType, defineComponent } from 'vue'
import { ContextmenuItem } from './types'

export default defineComponent({
  name: 'menu-content',
  props: {
    menus: {
      type: Array as PropType<ContextmenuItem[]>,
      required: true,
    },
    handleClickMenuItem: {
      type: Function,
      required: true,
    },
  },
})
