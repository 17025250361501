
import { defineComponent, PropType, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import { enterFullscreen } from '@/utils/fullscreen'
import useScreening from '@/hooks/useScreening'
import useExecPlay from './hooks/useExecPlay'
import useSlideSize from './hooks/useSlideSize'
import useFullscreen from './hooks/useFullscreen'

import ScreenSlideList from './ScreenSlideList.vue'
import SlideThumbnails from './SlideThumbnails.vue'
import WritingBoardTool from './WritingBoardTool.vue'

export default defineComponent({
  name: 'screen',
  components: {
    ScreenSlideList,
    SlideThumbnails,
    WritingBoardTool,
  },
  props: {
    changeViewMode: {
      type: Function as PropType<(mode: 'base' | 'presenter') => void>,
      required: true,
    },
  },
  setup(props) {
    const { slides, slideIndex } = storeToRefs(useSlidesStore())

    const {
      autoPlayTimer,
      autoPlay,
      closeAutoPlay,
      mousewheelListener,
      touchStartListener,
      touchEndListener,
      turnPrevSlide,
      turnNextSlide,
      turnSlideToIndex,
      turnSlideToId,
      execPrev,
      execNext,
      animationIndex,
    } = useExecPlay()

    const { slideWidth, slideHeight } = useSlideSize()
    const { exitScreening } = useScreening()
    const { fullscreenState, manualExitFullscreen } = useFullscreen()

    const rightToolsVisible = ref(false)
    const writingBoardToolVisible = ref(false)
    const slideThumbnailModelVisible = ref(false)
    const laserPen = ref(false)

    const contextmenus = (): ContextmenuItem[] => {
      return [
        {
          text: '上一页',
          subText: '↑ ←',
          disable: slideIndex.value <= 0,
          handler: () => turnPrevSlide(),
        },
        {
          text: '下一页',
          subText: '↓ →',
          disable: slideIndex.value >= slides.value.length - 1,
          handler: () => turnNextSlide(),
        },
        {
          text: '第一页',
          disable: slideIndex.value === 0,
          handler: () => turnSlideToIndex(0),
        },
        {
          text: '最后一页',
          disable: slideIndex.value === slides.value.length - 1,
          handler: () => turnSlideToIndex(slides.value.length - 1),
        },
        { divider: true },
        {
          text: '显示工具栏',
          handler: () => rightToolsVisible.value = true,
        },
        {
          text: '查看所有幻灯片',
          handler: () => slideThumbnailModelVisible.value = true,
        },
        {
          text: '画笔工具',
          handler: () => writingBoardToolVisible.value = true,
        },
        {
          text: '演讲者视图',
          handler: () => props.changeViewMode('presenter'),
        },
        { divider: true },
        {
          text: autoPlayTimer.value ? '取消自动放映' : '自动放映',
          handler: autoPlayTimer.value ? closeAutoPlay : autoPlay,
        },
        {
          text: '结束放映',
          subText: 'ESC',
          handler: exitScreening,
        },
      ]
    }

    return {
      slides,
      slideIndex,
      slideWidth,
      slideHeight,
      mousewheelListener,
      touchStartListener,
      touchEndListener,
      animationIndex,
      contextmenus,
      execPrev,
      execNext,
      turnSlideToIndex,
      turnSlideToId,
      slideThumbnailModelVisible,
      writingBoardToolVisible,
      rightToolsVisible,
      fullscreenState,
      exitScreening,
      enterFullscreen,
      manualExitFullscreen,
      laserPen,
    }
  },
})
