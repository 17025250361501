
import { computed, defineComponent, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { PPTElementLink } from '@/types/slides'
import useLink from '@/hooks/useLink'

import ThumbnailSlide from '@/views/components/ThumbnailSlide/index.vue'

type TypeKey = 'web' | 'slide'
interface TabItem {
  key: TypeKey;
  label: string;
}

export default defineComponent({
  name: 'link-dialog',
  emits: ['close'],
  components: {
    ThumbnailSlide,
  },
  setup(props, { emit }) {
    const { handleElement } = storeToRefs(useMainStore())
    const { slides } = storeToRefs(useSlidesStore())

    const type = ref<TypeKey>('web')
    const address = ref('')
    const slideId = ref('')

    const selectedSlide = computed(() => {
      if (!slideId.value) return null

      return slides.value.find(item => item.id === slideId.value) || null
    })

    const tabs: TabItem[] = [
      { key: 'web', label: '网页链接' },
      { key: 'slide', label: '幻灯片页面' },
    ]

    const { setLink } = useLink()

    onMounted(() => {
      if (handleElement.value?.link) {
        if (handleElement.value.link.type === 'web') address.value = handleElement.value.link.target
        else if (handleElement.value.link.type === 'slide') slideId.value = handleElement.value.link.target

        type.value = handleElement.value.link.type
      }
    })

    const close = () => emit('close')

    const save = () => {
      const link: PPTElementLink = {
        type: type.value,
        target: type.value === 'web' ? address.value : slideId.value,
      }
      if (handleElement.value) {
        const success = setLink(handleElement.value, link)
        if (success) close()
        else address.value = ''
      }
    }

    return {
      slides,
      tabs,
      type,
      address,
      slideId,
      selectedSlide,
      close,
      save,
    }
  },
})
