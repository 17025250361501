import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "element-style-panel" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.currentPanelComponent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " 请先选中要编辑的元素 "))
      : _createCommentVNode("", true),
    (_ctx.handleElement)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentPanelComponent), { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}