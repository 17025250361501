import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b8c5938"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "symbol-panel" }
const _hoisted_2 = { class: "tabs" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "pool" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "symbol" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.symbolPoolList, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["tab", { 'active': _ctx.selectedSymbolKey === item.key }]),
          key: item.key,
          onClick: ($event: any) => (_ctx.selectedSymbolKey = item.key)
        }, _toDisplayString(item.label), 11, _hoisted_3))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.symbolPool, (item, index) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "symbol-item",
          key: index,
          onClick: ($event: any) => (_ctx.selectSymbol(item))
        }, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(item), 1)
        ], 8, _hoisted_5))
      }), 128))
    ])
  ]))
}