import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconVolumeNotice = _resolveComponent("IconVolumeNotice")!
  const _component_AudioPlayer = _resolveComponent("AudioPlayer")!
  const _directive_contextmenu = _resolveDirective("contextmenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editable-element-audio", { 'lock': _ctx.elementInfo.lock }]),
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
      width: _ctx.elementInfo.width + 'px',
      height: _ctx.elementInfo.height + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "rotate-wrapper",
      style: _normalizeStyle({ transform: `rotate(${_ctx.elementInfo.rotate}deg)` })
    }, [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "element-content",
        onMousedown: _cache[1] || (_cache[1] = $event => _ctx.handleSelectElement($event))
      }, [
        _createVNode(_component_IconVolumeNotice, {
          class: "audio-icon",
          style: _normalizeStyle({
            fontSize: _ctx.audioIconSize,
            color: _ctx.elementInfo.color,
          })
        }, null, 8, ["style"]),
        (_ctx.handleElementId === _ctx.elementInfo.id)
          ? (_openBlock(), _createBlock(_component_AudioPlayer, {
              key: 0,
              class: "audio-player",
              style: _normalizeStyle({ ..._ctx.audioPlayerPosition }),
              src: _ctx.elementInfo.src,
              loop: _ctx.elementInfo.loop,
              scale: _ctx.canvasScale,
              onMousedown: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
            }, null, 8, ["style", "src", "loop", "scale"]))
          : _createCommentVNode("", true)
      ], 32)), [
        [_directive_contextmenu, _ctx.contextmenus]
      ])
    ], 4)
  ], 6))
}