
import { computed, defineComponent, PropType } from 'vue'
import { PPTChartElement } from '@/types/slides'

import ElementOutline from '@/views/components/element/ElementOutline.vue'
import Chart from './Chart.vue'

export default defineComponent({
  name: 'base-element-chart',
  components: {
    ElementOutline,
    Chart,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTChartElement>,
      required: true,
    },
    needScaleSize: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    return {
      chartWidth: computed(() => props.needScaleSize ? props.elementInfo.width * 10 : props.elementInfo.width),
      chartHeight: computed(() => props.needScaleSize ? props.elementInfo.height * 10 : props.elementInfo.height),
    }
  },
})
