import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-025539cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "placeholder"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThumbnailElement = _resolveComponent("ThumbnailElement")!

  return (_openBlock(), _createElementBlock("div", {
    class: "thumbnail-slide",
    style: _normalizeStyle({
      width: _ctx.size + 'px',
      height: _ctx.size * _ctx.viewportRatio + 'px',
    })
  }, [
    (_ctx.visible)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "elements",
          style: _normalizeStyle({
        width: _ctx.VIEWPORT_SIZE + 'px',
        height: _ctx.VIEWPORT_SIZE * _ctx.viewportRatio + 'px',
        transform: `scale(${_ctx.scale})`,
      })
        }, [
          _createElementVNode("div", {
            class: "background",
            style: _normalizeStyle(_ctx.backgroundStyle)
          }, null, 4),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slide.elements, (element, index) => {
            return (_openBlock(), _createBlock(_component_ThumbnailElement, {
              key: element.id,
              elementInfo: element,
              elementIndex: index + 1
            }, null, 8, ["elementInfo", "elementIndex"]))
          }), 128))
        ], 4))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, "加载中 ..."))
  ], 4))
}