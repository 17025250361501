
import { computed, defineComponent, ref, PropType, watchEffect } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore } from '@/store'
import { PPTElement } from '@/types/slides'
import { getElementListRange } from '@/utils/element'
import { OperateResizeHandlers, MultiSelectRange } from '@/types/edit'
import useCommonOperate from '../hooks/useCommonOperate'

import ResizeHandler from './ResizeHandler.vue'
import BorderLine from './BorderLine.vue'

export default defineComponent({
  name: 'multi-select-operate',
  components: {
    ResizeHandler,
    BorderLine,
  },
  props: {
    elementList: {
      type: Array as PropType<PPTElement[]>,
      required: true,
    },
    scaleMultiElement: {
      type: Function as PropType<(e: MouseEvent, range: MultiSelectRange, command: OperateResizeHandlers) => void>,
      required: true,
    },
  },
  setup(props) {
    const { activeElementIdList, canvasScale } = storeToRefs(useMainStore())

    const localActiveElementList = computed(() => props.elementList.filter(el => activeElementIdList.value.includes(el.id)))

    const range = ref({
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0,
    })

    // 根据多选元素整体在画布中的范围，计算边框线和缩放点的位置信息
    const width = computed(() => (range.value.maxX - range.value.minX) * canvasScale.value)
    const height = computed(() => (range.value.maxY - range.value.minY) * canvasScale.value)
    const { resizeHandlers, borderLines } = useCommonOperate(width, height)

    // 计算多选元素整体在画布中的范围
    const setRange = () => {
      const { minX, maxX, minY, maxY } = getElementListRange(localActiveElementList.value)
      range.value = { minX, maxX, minY, maxY }
    }
    watchEffect(setRange)

    // 禁用多选状态下缩放：仅未旋转的图片和形状可以在多选状态下缩放
    const disableResize = computed(() => {
      return localActiveElementList.value.some(item => {
        if (
          (item.type === 'image' || item.type === 'shape') && 
          !item.rotate
        ) return false
        return true
      })
    })

    return {
      range,
      canvasScale,
      borderLines,
      disableResize,
      resizeHandlers,
    }
  },
})
