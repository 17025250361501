
import { defineComponent, PropType, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore, useSlidesStore } from '@/store'
import { ElementTypes, PPTElement, PPTLineElement, PPTVideoElement, PPTAudioElement } from '@/types/slides'
import { OperateLineHandlers, OperateResizeHandlers } from '@/types/edit'

import ImageElementOperate from './ImageElementOperate.vue'
import TextElementOperate from './TextElementOperate.vue'
import ShapeElementOperate from './ShapeElementOperate.vue'
import LineElementOperate from './LineElementOperate.vue'
import TableElementOperate from './TableElementOperate.vue'
import CommonElementOperate from './CommonElementOperate.vue'
import LinkHandler from './LinkHandler.vue'

export default defineComponent({
  name: 'operate',
  components: {
    LinkHandler,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTElement>,
      required: true,
    },
    isSelected: {
      type: Boolean,
      required: true,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    isActiveGroupElement: {
      type: Boolean,
      required: true,
    },
    isMultiSelect: {
      type: Boolean,
      required: true,
    },
    rotateElement: {
      type: Function as PropType<(element: Exclude<PPTElement, PPTLineElement | PPTVideoElement | PPTAudioElement>) => void>,
      required: true,
    },
    scaleElement: {
      type: Function as PropType<(e: MouseEvent, element: Exclude<PPTElement, PPTLineElement>, command: OperateResizeHandlers) => void>,
      required: true,
    },
    dragLineElement: {
      type: Function as PropType<(e: MouseEvent, element: PPTLineElement, command: OperateLineHandlers) => void>,
      required: true,
    },
    openLinkDialog: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  setup(props) {
    const { canvasScale, toolbarState } = storeToRefs(useMainStore())
    const { currentSlide } = storeToRefs(useSlidesStore())

    const currentOperateComponent = computed(() => {
      const elementTypeMap = {
        [ElementTypes.IMAGE]: ImageElementOperate,
        [ElementTypes.TEXT]: TextElementOperate,
        [ElementTypes.SHAPE]: ShapeElementOperate,
        [ElementTypes.LINE]: LineElementOperate,
        [ElementTypes.TABLE]: TableElementOperate,
        [ElementTypes.CHART]: CommonElementOperate,
        [ElementTypes.LATEX]: CommonElementOperate,
        [ElementTypes.VIDEO]: CommonElementOperate,
        [ElementTypes.AUDIO]: CommonElementOperate,
      }
      return elementTypeMap[props.elementInfo.type] || null
    })

    const elementIndexInAnimation = computed(() => {
      const animations = currentSlide.value.animations || []
      return animations.findIndex(animation => animation.elId === props.elementInfo.id)
    })

    const rotate = computed(() => 'rotate' in props.elementInfo ? props.elementInfo.rotate : 0)
    const height = computed(() => 'height' in props.elementInfo ? props.elementInfo.height : 0)

    return {
      currentOperateComponent,
      canvasScale,
      toolbarState,
      elementIndexInAnimation,
      rotate,
      height,
    }
  },
})
