
import { defineComponent, PropType } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore } from '@/store'
import { PPTVideoElement } from '@/types/slides'
import { ContextmenuItem } from '@/components/Contextmenu/types'

import VideoPlayer from './VideoPlayer/index.vue'

export default defineComponent({
  name: 'editable-element-video',
  components: {
    VideoPlayer,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTVideoElement>,
      required: true,
    },
    selectElement: {
      type: Function as PropType<(e: MouseEvent, element: PPTVideoElement, canMove?: boolean) => void>,
      required: true,
    },
    contextmenus: {
      type: Function as PropType<() => ContextmenuItem[]>,
    },
  },
  setup(props) {
    const { canvasScale } = storeToRefs(useMainStore())

    const handleSelectElement = (e: MouseEvent, canMove = true) => {
      if (props.elementInfo.lock) return
      e.stopPropagation()

      props.selectElement(e, props.elementInfo, canMove)
    }

    return {
      canvasScale,
      handleSelectElement,
    }
  },
})
