import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "editable-element",
    ref: "elementRef",
    id: `editable-element-${_ctx.elementInfo.id}`,
    style: _normalizeStyle({
      zIndex: _ctx.elementIndex,
    })
  }, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentElementComponent), {
      elementInfo: _ctx.elementInfo,
      selectElement: _ctx.selectElement,
      contextmenus: _ctx.contextmenus
    }, null, 8, ["elementInfo", "selectElement", "contextmenus"]))
  ], 12, _hoisted_1))
}