import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2ed6935e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tools-left" }
const _hoisted_2 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ScreenSlideList = _resolveComponent("ScreenSlideList")!
  const _component_SlideThumbnails = _resolveComponent("SlideThumbnails")!
  const _component_WritingBoardTool = _resolveComponent("WritingBoardTool")!
  const _component_IconLeftTwo = _resolveComponent("IconLeftTwo")!
  const _component_IconRightTwo = _resolveComponent("IconRightTwo")!
  const _component_IconWrite = _resolveComponent("IconWrite")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_IconMagic = _resolveComponent("IconMagic")!
  const _component_IconListView = _resolveComponent("IconListView")!
  const _component_IconOffScreenOne = _resolveComponent("IconOffScreenOne")!
  const _component_IconFullScreenOne = _resolveComponent("IconFullScreenOne")!
  const _component_IconPower = _resolveComponent("IconPower")!
  const _directive_contextmenu = _resolveDirective("contextmenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["base-view", { 'laser-pen': _ctx.laserPen }])
  }, [
    _withDirectives(_createVNode(_component_ScreenSlideList, {
      slideWidth: _ctx.slideWidth,
      slideHeight: _ctx.slideHeight,
      animationIndex: _ctx.animationIndex,
      turnSlideToId: _ctx.turnSlideToId,
      manualExitFullscreen: _ctx.manualExitFullscreen,
      onMousewheel: _cache[0] || (_cache[0] = $event => _ctx.mousewheelListener($event)),
      onTouchstart: _cache[1] || (_cache[1] = $event => _ctx.touchStartListener($event)),
      onTouchend: _cache[2] || (_cache[2] = $event => _ctx.touchEndListener($event))
    }, null, 8, ["slideWidth", "slideHeight", "animationIndex", "turnSlideToId", "manualExitFullscreen"]), [
      [_directive_contextmenu, _ctx.contextmenus]
    ]),
    (_ctx.slideThumbnailModelVisible)
      ? (_openBlock(), _createBlock(_component_SlideThumbnails, {
          key: 0,
          turnSlideToIndex: _ctx.turnSlideToIndex,
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.slideThumbnailModelVisible = false))
        }, null, 8, ["turnSlideToIndex"]))
      : _createCommentVNode("", true),
    (_ctx.writingBoardToolVisible)
      ? (_openBlock(), _createBlock(_component_WritingBoardTool, {
          key: 1,
          slideWidth: _ctx.slideWidth,
          slideHeight: _ctx.slideHeight,
          onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.writingBoardToolVisible = false))
        }, null, 8, ["slideWidth", "slideHeight"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_IconLeftTwo, {
        class: "tool-btn",
        theme: "two-tone",
        fill: ['#111', '#fff'],
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.execPrev()))
      }),
      _createVNode(_component_IconRightTwo, {
        class: "tool-btn",
        theme: "two-tone",
        fill: ['#111', '#fff'],
        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.execNext()))
      })
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["tools-right", { 'visible': _ctx.rightToolsVisible }]),
      onMouseleave: _cache[14] || (_cache[14] = ($event: any) => (_ctx.rightToolsVisible = false)),
      onMouseenter: _cache[15] || (_cache[15] = ($event: any) => (_ctx.rightToolsVisible = true))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "tool-btn page-number",
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.slideThumbnailModelVisible = true))
        }, "幻灯片 " + _toDisplayString(_ctx.slideIndex + 1) + " / " + _toDisplayString(_ctx.slides.length), 1),
        _createVNode(_component_Tooltip, {
          mouseLeaveDelay: 0,
          mouseEnterDelay: 0.3,
          title: "画笔工具"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconWrite, {
              class: "tool-btn",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.writingBoardToolVisible = true))
            })
          ]),
          _: 1
        }, 8, ["mouseEnterDelay"]),
        _createVNode(_component_Tooltip, {
          mouseLeaveDelay: 0,
          mouseEnterDelay: 0.3,
          title: "激光笔"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconMagic, {
              class: _normalizeClass(["tool-btn", { 'active': _ctx.laserPen }]),
              onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.laserPen = !_ctx.laserPen))
            }, null, 8, ["class"])
          ]),
          _: 1
        }, 8, ["mouseEnterDelay"]),
        _createVNode(_component_Tooltip, {
          mouseLeaveDelay: 0,
          mouseEnterDelay: 0.3,
          title: "演讲者视图"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconListView, {
              class: "tool-btn",
              onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.changeViewMode('presenter')))
            })
          ]),
          _: 1
        }, 8, ["mouseEnterDelay"]),
        _createVNode(_component_Tooltip, {
          mouseLeaveDelay: 0,
          mouseEnterDelay: 0.3,
          title: _ctx.fullscreenState ? '退出全屏' : '进入全屏'
        }, {
          default: _withCtx(() => [
            (_ctx.fullscreenState)
              ? (_openBlock(), _createBlock(_component_IconOffScreenOne, {
                  key: 0,
                  class: "tool-btn",
                  onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.manualExitFullscreen()))
                }))
              : (_openBlock(), _createBlock(_component_IconFullScreenOne, {
                  key: 1,
                  class: "tool-btn",
                  onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.enterFullscreen()))
                }))
          ]),
          _: 1
        }, 8, ["mouseEnterDelay", "title"]),
        _createVNode(_component_Tooltip, {
          mouseLeaveDelay: 0,
          mouseEnterDelay: 0.3,
          title: "结束放映"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_IconPower, {
              class: "tool-btn",
              onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.exitScreening()))
            })
          ]),
          _: 1
        }, 8, ["mouseEnterDelay"])
      ])
    ], 34)
  ], 2))
}