import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3168526a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height", "stroke", "stroke-width"]
const _hoisted_2 = ["transform"]
const _hoisted_3 = ["d"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_contextmenu = _resolveDirective("contextmenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editable-element-latex", { 'lock': _ctx.elementInfo.lock }]),
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
      width: _ctx.elementInfo.width + 'px',
      height: _ctx.elementInfo.height + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "rotate-wrapper",
      style: _normalizeStyle({ transform: `rotate(${_ctx.elementInfo.rotate}deg)` })
    }, [
      _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "element-content",
        onMousedown: _cache[0] || (_cache[0] = $event => _ctx.handleSelectElement($event)),
        onDblclick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openLatexEditor()))
      }, [
        (_openBlock(), _createElementBlock("svg", {
          overflow: "visible",
          width: _ctx.elementInfo.width,
          height: _ctx.elementInfo.height,
          stroke: _ctx.elementInfo.color,
          "stroke-width": _ctx.elementInfo.strokeWidth,
          fill: "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }, [
          _createElementVNode("g", {
            transform: `scale(${_ctx.elementInfo.width / _ctx.elementInfo.viewBox[0]}, ${_ctx.elementInfo.height / _ctx.elementInfo.viewBox[1]}) translate(0,0) matrix(1,0,0,1,0,0)`
          }, [
            _createElementVNode("path", {
              d: _ctx.elementInfo.path
            }, null, 8, _hoisted_3)
          ], 8, _hoisted_2)
        ], 8, _hoisted_1))
      ], 32)), [
        [_directive_contextmenu, _ctx.contextmenus]
      ])
    ], 4)
  ], 6))
}