
import { defineComponent, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useSlidesStore } from '@/store'
import useExport from '@/hooks/useExport'

import ThumbnailSlide from '@/views/components/ThumbnailSlide/index.vue'

export default defineComponent({
  name: 'export-img-dialog',
  components: {
    ThumbnailSlide,
  },
  setup(props, { emit }) {
    const { slides } = storeToRefs(useSlidesStore())

    const imageThumbnailsRef = ref<HTMLElement>()
    const format = ref<'jpeg' | 'png'>('jpeg')
    const quality = ref(1)
    const ignoreWebfont = ref(true)

    const close = () => emit('close')

    const { exportImage, exporting } = useExport()

    const expImage = () => {
      if (!imageThumbnailsRef.value) return
      exportImage(imageThumbnailsRef.value, format.value, quality.value, ignoreWebfont.value)
    }
    
    return {
      imageThumbnailsRef,
      slides,
      format,
      quality,
      ignoreWebfont,
      exporting,
      expImage,
      close,
    }
  },
})
