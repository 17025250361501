import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ef8b6c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "element-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VideoPlayer = _resolveComponent("VideoPlayer")!

  return (_openBlock(), _createElementBlock("div", {
    class: "screen-element-video",
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
      width: _ctx.elementInfo.width + 'px',
      height: _ctx.elementInfo.height + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "rotate-wrapper",
      style: _normalizeStyle({ transform: `rotate(${_ctx.elementInfo.rotate}deg)` })
    }, [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.inCurrentSlide)
          ? (_openBlock(), _createBlock(_component_VideoPlayer, {
              key: 0,
              width: _ctx.elementInfo.width,
              height: _ctx.elementInfo.height,
              src: _ctx.elementInfo.src,
              poster: _ctx.elementInfo.poster,
              scale: _ctx.scale
            }, null, 8, ["width", "height", "src", "poster", "scale"]))
          : _createCommentVNode("", true)
      ])
    ], 4)
  ], 4))
}