import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18f794c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pptist-screen" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseView = _resolveComponent("BaseView")!
  const _component_PresenterView = _resolveComponent("PresenterView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.viewMode === 'base')
      ? (_openBlock(), _createBlock(_component_BaseView, {
          key: 0,
          changeViewMode: _ctx.changeViewMode
        }, null, 8, ["changeViewMode"]))
      : (_ctx.viewMode === 'presenter')
        ? (_openBlock(), _createBlock(_component_PresenterView, {
            key: 1,
            changeViewMode: _ctx.changeViewMode
          }, null, 8, ["changeViewMode"]))
        : _createCommentVNode("", true)
  ]))
}