import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconPlayOne = _resolveComponent("IconPlayOne")!

  return (_openBlock(), _createElementBlock("div", {
    class: "base-element-video",
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
      width: _ctx.elementInfo.width + 'px',
      height: _ctx.elementInfo.height + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "rotate-wrapper",
      style: _normalizeStyle({ transform: `rotate(${_ctx.elementInfo.rotate}deg)` })
    }, [
      _createElementVNode("div", {
        class: "element-content",
        style: _normalizeStyle({ backgroundImage: `url(${_ctx.elementInfo.poster})` })
      }, [
        _createVNode(_component_IconPlayOne, { class: "icon" })
      ], 4)
    ], 4)
  ], 4))
}