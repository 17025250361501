
import { defineComponent, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useMainStore } from '@/store'
import useScreening from '@/hooks/useScreening'
import useSlideHandler from '@/hooks/useSlideHandler'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'
import useExport from '@/hooks/useExport'

import HotkeyDoc from './HotkeyDoc.vue'
import ExportImgDialog from './ExportImgDialog.vue'

export default defineComponent({
  name: 'editor-header',
  components: {
    HotkeyDoc,
    ExportImgDialog,
  },
  setup() {
    const mainStore = useMainStore()
    const { showGridLines } = storeToRefs(mainStore)

    const { enterScreening, enterScreeningFromStart } = useScreening()
    const { createSlide, deleteSlide, resetSlides } = useSlideHandler()
    const { redo, undo } = useHistorySnapshot()
    const { exporting, exportJSON, exportPPTX } = useExport()

    const toggleGridLines = () => {
      mainStore.setGridLinesState(!showGridLines.value)
    }

    const hotkeyDrawerVisible = ref(false)
    const exportImgDialogVisible = ref(false)

    const goIssues = () => {
      window.open('https://github.com/pipipi-pikachu/PPTist/issues')
    }

    return {
      redo,
      undo,
      showGridLines,
      hotkeyDrawerVisible,
      exportImgDialogVisible,
      exporting,
      enterScreening,
      enterScreeningFromStart,
      createSlide,
      deleteSlide,
      toggleGridLines,
      resetSlides,
      exportJSON,
      exportPPTX,
      goIssues,
    }
  },
})
